import React from 'react'
import { Link } from 'react-router-dom'

const Test = () => {
  return (
    <div style={{padding:'100px 0px 0px 0px'}}>
      <Link to={`/xyz/${'Contest'}`}>
        {/* <h1></h1> */}
        Contest
      </Link>

      <br />
      <Link to={`/xyz/${'Prep'}`}>
      Prep
      </Link>
    </div>
  )
}

export default Test