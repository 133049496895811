import React, { useState } from "react";  
import { AiOutlineClose } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
// import Modal from "../../Modal/Modal";
import Modal from "react-modal";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { FileUploader } from "react-drag-drop-files";
import "./Replydata.css";
import {toast} from 'react-hot-toast'

const Replydata = () => {

  const notify = () => toast.success('Post Successfully.');


  function firstClick() {
    // add another onclick funcion 
  
      // logInWithEmailAndPassword(email, password).then(() =>
      //   navigate("/Home")
      // )
      notify()
  }
  

  const fileTypes = ["JPEG", "PNG"];
const [file, setFile] = useState(null);
const handleChange = (file) => {
  setFile(file);
};
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="reply-bg">
             <div className="body-fix-h">
             <div className="wrapper ">
      <div className="side-reply">
        <div className="sidebar-home-d">
          <Sidebar1 />
        </div>
        <div className="main-y">

        <div className="data-reply">
          <div className="reply-data-s">
            <div className="reply-main-data">
              <div className="border-reply">
                <div>
                  <div className="head-reply-comment">
                    <div>
                      <button className="btn-head-comm">
                        Contest Prep Level 1
                      </button>
                      <h1 className="reply-h1">Bloodwork question</h1>
                      <div className="span-2-reply">
                        <span>Posted by</span>
                        <span className="name-hov"> john</span>
                        <span> on</span>
                        <span> 7 January 2023 </span>
                        <span> at</span>
                        <span> 11:19 am</span>
                      </div>
                      <div className="para-1-reply">
                        <p>
                          Just got blood work back and my whiteblood cell count
                          was 3.6,just outside ofnormal range on low side. My
                          platelet count was 117 which is also low,and then
                          absolute neutrophils was 1436 which is below normal
                          1500….recommendations or concerns !?
                        </p>
                      </div>
                      <div className="span-2-reply">
                        <span className="name-hov">Brady</span>
                        <span> replied</span>
                        <span className="name-hov">
                          {" "}
                          4 hours, 9 minutes ago
                        </span>
                        <span> 2 Members </span>
                        <span> · 5 Replies</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="reply-count reply-btn-count">
                  <h1>5 Replies</h1>
                  <div className="btn-side">
              <button onClick={openModal} className="btn-reply-s">
                Reply
              </button>
              </div>

                </div>
                <hr />
                <div className="another-reply-1">
                  <div className="user-dp-time-member">
                    <div>
                      <img
                        className="user-reply-dp"
                        src={require("../../Img/user.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="user-data-t">
                      <h1>Brady</h1>
                      <p>Member 7 January 2023 at 12:01 pm</p>
                    </div>
                  </div>
                  <div className="section-reply-p2">
                    <p>What does your iron intake look like?</p>
                  </div>
                </div>
                <hr />
                <div className="another-reply-1">
                  <div className="user-dp-time-member">
                    <div>
                      <img
                        className="user-reply-dp"
                        src={require("../../Img/user.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="user-data-t">
                      <h1>Brady</h1>
                      <p>Member 7 January 2023 at 12:01 pm</p>
                    </div>
                  </div>
                  <div className="section-reply-p2">
                    <p>What does your iron intake look like?</p>
                  </div>
                </div>
                <hr />
                <div className="another-reply-1">
                  <div className="user-dp-time-member">
                    <div>
                      <img
                        className="user-reply-dp"
                        src={require("../../Img/user.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="user-data-t">
                      <h1>Brady</h1>
                      <p>Member 7 January 2023 at 12:01 pm</p>
                    </div>
                  </div>
                  <div className="section-reply-p2">
                    <p>What does your iron intake look like?</p>
                  </div>
                </div>
                <hr />
                <div className="another-reply-1">
                  <div className="user-dp-time-member">
                    <div>
                      <img
                        className="user-reply-dp"
                        src={require("../../Img/user.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="user-data-t">
                      <h1>Brady</h1>
                      <p>Member 7 January 2023 at 12:01 pm</p>
                    </div>
                  </div>
                  <div className="section-reply-p2">
                    <p>What does your iron intake look like?</p>
                  </div>
                </div>
                <hr />
                <div className="another-reply-1">
                  <div className="user-dp-time-member">
                    <div>
                      <img
                        className="user-reply-dp"
                        src={require("../../Img/user.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="user-data-t">
                      <h1>Brady</h1>
                      <p>Member 7 January 2023 at 12:01 pm</p>
                    </div>
                  </div>
                  <div className="section-reply-p2">
                    <p>What does your iron intake look like</p>
                  </div>
                </div>
              </div>
              <div className="btn-side">
              {/* <button onClick={openModal} className="btn-reply-s">
                Reply
              </button> */}
              </div>
            </div>
          </div>
        
        </div>
        </div>

      </div>
</div>
</div>

    
      <div>
        {/* <button onClick={openModal}>Open Modal</button> */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          {/* <button onClick={closeModal}>close</button> */}

         
            <div className="section1-reply">
        <div className="reply-headings">
              <h1 className="reply-h1-y">Reply to: Brady</h1>
              <h1 className="reply-h2-y">I’m 7 weeks out from the Arnold Amateur and…...</h1>
        </div>
          
              <div className="svg-close-modal">
                <AiOutlineClose onClick={closeModal} />
              </div>
            </div>
            <hr />
            <form >

          
<div className="section-2-reply">
  <div className="text-area-modal-s">
    <textarea 
    className="section-2-replytext"
    placeholder="Type your reply here"
    name="" id="" cols="30" rows="10"></textarea>
  </div>
  <div className="upload-options2">
  
    <FileUploader
multiple={true}
handleChange={handleChange}
name="file"
types={fileTypes}
/>

  </div>

</div>
<hr />
<div
className="section-2-reply-1"
>
<textarea 
placeholder="Type one or more tag, comma separated "
className="text-area-modal-2"
name="" id="" cols="30" rows="10"></textarea>
</div>
<hr />
<div className="check-box-post-btn-s">
 <div>
 <input type="checkbox" id="html" name="fav_language" value="HTML"/>
  <label >Notify me of replies via emailL</label>
 </div>
 <div>
  <button
   onClick={() => {firstClick(); }}
  className="post-btn-dis1">Post</button>
 </div>

</div>

</form>
     
        </Modal>
      </div>
    </div>
  );
};

export default Replydata;
