import { Outlet, Navigate, useNavigate} from "react-router-dom";
import Navbar from "../Features/Navbar/Navbar";

import { useEffect, useState} from "react";
import { auth } from "../Firebase/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import ScrollToTop from "../Scrollontop";
import { onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../../context/AuthContext";
import Navbar2 from "../Features/Navbar/Navbar2";

const PrivateRoutes = () => {
  const navigate = useNavigate()

  // authUser = { isLoggedIn: false }
  
  const [user] = useAuthState(auth);
  
    // useEffect(() => {
   
    //   if (user) navigate("/");
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [user]);


    // const [authUser,setAuthUser] = useState(false)

    // useEffect(() => {
    //     onAuthStateChanged(user.auth, (user) => {
    //       user ? setAuthUser(user) : setAuthUser(false);
    //     });
    //   }, [user]);
    



      const authUser = useAuthState(auth);
    
      if (authUser === undefined) {
        return null; // or loading spinner, etc...
      }



  return authUser ?<>
     <ScrollToTop />
   
          <Navbar/>


  <Outlet />
  </> 
   : <Navigate to="/login" />;
};

export default PrivateRoutes;
