import React from "react";
import "./Modules.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Modules = () => {
  const navigate = useNavigate(); 
  return (
    <div className="bg-module">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="module-div">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y modules11">
              <div className="module-a1">
                <div>
                  <div className="module-a2">
                    <div className="module-a3">
                      <h1 className="module-h1">
                        contest prep Level 1 Certification
                      </h1>
                      <h1 className="module-h2">View Modules details </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="module-a4">
                <div className="section-1-module">
                <div className="module-a5">
                  {/*  */}
                  <div>
                    <div className="module-a6">
                      <div>
                        <h1 className="module-h3">41% Complete</h1>
                        <h1 className="module-h4">
                          Last activity on 18 November 2022
                        </h1>
                      </div>
                      <div class="progress-Modules">
                        <div class="progress-value-Modules"></div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="module-a7">
                    <div className="module-a8">
                      <p>
                        This Modules is developed to teach competitors and
                        coaches how to implement all aspects of bodybuilding at
                        a higher level to take steps forward in muscle mass and
                        conditioning. You will learn the thought process behind
                        all decisions, so you can self-coach and coach others to
                        achieve higher level physique goals.{" "}
                      </p>
                    </div>
                    <div className="module-a9">
                      <p>
                        This Modules will lead you to make the right choices on
                        planning offseason, contest prep, and the post show
                        period. Nothing is off limits you will be able to make
                        decisions on when, why, and how to implement nutrition,
                        training, supplements and PEDs into your contest year.{" "}
                      </p>
                    </div>
                    <div className="module-a10">
                      <p>
                        This Modules is to help you navigate and avoid many
                        common pitfalls competitors make and prevent you from
                        wasting time making progress. This Modules is not for
                        the beginner, to replace a coach, provide individualized
                        advice, or an in-depth dive into the scientific
                        research. This is an evidence-based approach to
                        bodybuilding constructed on peer-reviewed literature,
                        observation in the field of practice and individual
                        needs/preferences.{" "}
                      </p>
                    </div>
                    <div className="module-a11">
                      <p>
                        Requirements: Basic understanding of competitive
                        physique sport and a beginner understanding of
                        nutrition, training, and supplements.
                      </p>
                    </div>
                    <div className="module-a12">
                      <p>
                        The Modules will be continued to be updated as new
                        evidence and questions arise.
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div>
                    <div>
                      <h1 className="module-h5">MODULES CONTENT</h1>
                    </div>
                    {/* Module 1 */}
                    <div>
                      <div>
                        <h1 
                      
                        className="module-h6">MODULE 1 - INTRODUCTION</h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1
                            onClick={()=> navigate('/Modules/Lessons')}
                          className="module-h7">WHY WE STARTED </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1
                            onClick={()=> navigate('/Modules/Lessons')}
                          className="module-h8">WHAT YOUR PURPOSE</h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">PHYSQUE ASSESSMENT </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                            TAKING THE RIGHT STEP (bulk, cut and maintenance and
                            yes & no in ped’s)
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                      </div>
                    </div>
                    {/* Module 2 */}
                    <div>
                      <div>
                        <h1 className="module-h6">
                          MODULE 2 - NUTRITION MODULE
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                            CALORIES for SHREDDING AND MASSING UP
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">PROTEIN </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8"> CARBOHYDRATE</h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">FATS </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                            GI TRACT FUNCTIONS AND ENERGY DELIVERY
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                          ELECTROLYTES AND FLUID BALANCE 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                    IMPORTANCE OF MICRONUTRIENTS 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                            <h1 className="module-h8">
                            NUTRITION AROUND TRAINING  and NUTRIENT TIMING
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                            <h1 className="module-h8">
              SUPPLEMENTATION 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                            <h1 className="module-h8">
                          REFEEDS AND CHEAT MEALS
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                      </div>
                      
                    </div>
                      {/* Module-3 */}
                      <div>
                      <div>
                        <h1 className="module-h6">
                        MODULE 3 - TRAINING 
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                          MAIN DRIVER OF HYPERTROPHY 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Need and analysis </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8"> VOLUME, INTESITY AND FREQUENCY</h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Basic concepts of hypertrophy </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                     Basic concepts of hypertrophy 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                         EXERCISE SELECTION  
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                    IMPROVING A LAGGING BODY PART
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                            <h1 className="module-h8">
                     TRAINING PROGRESSION 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                            <h1 className="module-h8">
                       FATUGUE MANAGEMENT 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                            <h1 className="module-h8">
           INSIGHTS OF SOME POPULAR TRAINING METHODS AND THEIR IMPLEMENTATION 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                            <div className="module-a14">
                              <div>

                            <h1 className="module-h8">
                         	TRAINING AROUND INJURIES
                          </h1>
                              </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                      </div>
                      
                    </div>
                    {/* Module - 4 */}
                    <div>
                      <div>
                        <h1 className="module-h6">
                        MODULE 4 - RECOVERY TOOLS 
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                       	UNDERSTANDING WHY IT’S THE MOST IMPORTANT FACTOR
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">PHYSIOTHERAPY TOOLS</h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
                        </div>
                        <div className="module-a14">
                          <h1 className="module-h8">SLEEP </h1>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                          />
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">MANAGING PSYCHOLOGICAL STRESS, TRAINING STRESS AND REST </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                          I.V. INFUSIONS
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                     DELOAD, TAPER and REST DAYS IN TRAINING 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
              
                        </div>
                       
                      </div>
                      
                    </div>
                    {/* Module - 5 */}
                    <div>
                      <div>
                        <h1 className="module-h6">
                        MODULE 5 - ANALYSING THE ORGAN HEALTH AND LAB MARKERS
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                         MONITERING THE HEALTH RISKS
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">HEART HEALTH </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">LIVER HEALTH</h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">KIDNEY HEATH </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                         MANAGING BLOOD PRESSURE AND BLOOD GLUCOSE5.	MANAGING BLOOD PRESSURE AND BLOOD GLUCOSE
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
                        </div>
                        <div className="module-a14">
                          <div>
                            
                          <h1 className="module-h8">
                      MANAGING THE SIDE EFFECTS POST CYCLE or ON CYCLE 
                          </h1>
                          </div>
                          <div>

<AiFillCheckCircle
  size={28}
  style={{ color: "#bc0000" }}
  />
  </div>
                        </div>
                       
                      </div>
                      
                    </div>
                    {/* Module - 6 */}
                    <div>
                      <div>
                        <h1 className="module-h6">
                        MODULE 6 - PERFORMANCE ENHANCING DRUGS 
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                   MODULE 6 - PERFORMANCE ENHANCING DRUGS 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Testosterone </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">DHT derivative anabolics </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">19-NOR derivative anabolics </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                     Other categories 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                     Growth hormone & Peptides 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                      Insulin 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                         PED Periodization 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                        Estrogen and prolactin management 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                    	PED’s for women
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                       
                      </div>
                      
                    </div>
                    {/* Module - 7 */}
                    <div>
                      <div>
                        <h1 className="module-h6">
                        MODULE 7 – OFF-SEASON  
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                      When to start and how long should be the off-season
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Nutrition periodization </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Training periodization </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Managing insulin sensitivity, gut health and appetite </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                         	Essential supplement 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                       Choosing the right off-season PED’s and progression with PED’s
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                 Controlling the body fat levels
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                        Cardiovascular health during off-season
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                     
                       
                      </div>
                      
                    </div>
                    {/* Module - 8 */}
                    <div>
                      <div>
                        <h1 className="module-h6">
                        MODULE 8 – CONTEST PREP  
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                     Nutrition periodization during prep
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Training periodization </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Contest prep supplementation</h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>
 
                          <h1 className="module-h8">Managing fatigue during prep </h1>
                          </div>
                          <div>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">
                         PED’s , selection and dose optimization 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                     
                       
                      </div>
                      
                    </div>
                    {/* Module - 9 */}
                    <div>
                      <div>
                        <h1 className="module-h6">
                        MODULE 9 – GETTING READY FOR THE STAGE 
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                          PEAK WEEK 
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">PRESENTATION – POSING ROUTINE AND COSTUME SELECTION </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">DAY BEFORE SHOW DAY</h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>
 
                          <h1 className="module-h8">SHOW DAY</h1>
                          </div>
                          <div>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                    
                     
                       
                      </div>
                      
                    </div>
                    {/* Module - 10 */}
                    <div >
                      <div>
                        <h1 className="module-h6">
                        MODULE 10 – POST SHOW   
                        </h1>
                      </div>

                      <div className="modules-title">
                        <div className="module-a13">
                          <div>

                          <h1 className="module-h7">
                  	Rebound
                          </h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Blood work</h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>

                          <h1 className="module-h8">Planning a PCT</h1>
                          </div>
                          <div>

                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>
 
                          <h1 className="module-h8">	A complete off</h1>
                          </div>
                          <div>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>
 
                          <h1 className="module-h8">Planning the next show</h1>
                          </div>
                          <div>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>
 
                          <h1 className="module-h8">Diet planning post show</h1>
                          </div>
                          <div>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>
 
                          <h1 className="module-h8">	Training periodization post show </h1>
                          </div>
                          <div>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                        <div className="module-a14">
                          <div>
 
                          <h1 className="module-h8">	PED framework (starting with a base)</h1>
                          </div>
                          <div>
                          <AiFillCheckCircle
                            size={28}
                            style={{ color: "#bc0000" }}
                            />
                            </div>
                        </div>
                     
                       
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="module-a020">

                <div className="module-a20">
                <div class="card-SideMoudle">
  <img  src={require ('./../../Img/bg-module.jpg')} alt="Image"/>
  <div class="card-description">
    <h1 className="module-h15">In Progress</h1>
   <button className="btn-1-Modules">Continue</button>
  </div>
</div>

                </div>
                </div>

                     </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modules;
