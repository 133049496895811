import React, { useState } from "react";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { HiOutlineUser } from "react-icons/hi";
import "./Account.css";
import { CiSettings } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
const Account = () => {
  const notify = () => toast.success(" Successfully Saved.");
  const notify2 = () => toast.success(" Account Delete Successfully.");


  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const navigate = useNavigate();

  return (
    <div className="account-bg">
      <div className=" body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="main-y">
            <div className="Section1-Account">
              <div className="Section2-Account">
                <div className="Section3-Account">
                  <h1 className="account-h1">Account Settings</h1>
                  <button
                    onClick={() => navigate("/User")}
                    className="acc-btn-1"
                  >
                    <div className="btn-d">
                      <HiOutlineUser />
                      View My Profile
                    </div>
                  </button>
                </div>
                <Tabs>
                  <div className="section4-Account">
                    <div className="lg-acc1">
                      <TabList className="tablist-2-acc">
                        <Tab
                          onClick={() => toggleTab(1)}
                          className={toggleState === 1 ? "bb2" : "bb3"}
                        >
                          <div className="lg-div1">
                            <CiSettings />
                            <h1 className="acc-h2">Login Information</h1>
                          </div>
                        </Tab>
                        <hr />
                        <Tab
                          onClick={() => toggleTab(2)}
                          className={toggleState === 2 ? "bb2" : "bb3"}
                        >
                          <div className="lg-div2">
                            <RiDeleteBin6Line />
                            <h1 className="acc-h3">Delete Account</h1>
                          </div>
                        </Tab>
                      </TabList>
                      <hr className="hr-1-acc" />
                    </div>
                    <div className="rh-acc1">
                      <TabPanel>
                        <form>
                          <div className="acc-TabPanel1">
                            <h1 className="acc-h4">Login Information</h1>
                            <p className="acc-p1">
                              Current Password (required to update email or
                              change current password)
                            </p>
                            <div className="password-input-container">
                              <input
                                className="input-box-1-acc"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <div
                                className="password-icon"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </div>
                            </div>
                            <div className="">
                              <h1 className="acc-h5">Lost your password?</h1>
                            </div>
                            <div className="email-input-acc">
                              <label className="label-1acc">
                                Account Email
                              </label>
                              <input
                                className="input-box-1-acc"
                                type="email"
                                name=""
                                id=""
                                required
                              />
                            </div>
                            <div className="acc-2-a">
                              <div className="acc-a2">
                                <span>
                                  <IoIosInformationCircle />
                                </span>
                                <p>
                                  Leave password fields blank for no change.
                                </p>
                              </div>
                            </div>

                            <div className="addyour">
                              <div>
                                <label htmlFor="">Add Your New Password</label>
                                <div className="password-input-container">
                                  <input
                                    className="input-box-1-acc"
                                    type={showPassword1? "text" : "password"}
                                    value={password1}
                                    onChange={(e) =>
                                      setPassword1(e.target.value)
                                    }
                                  />
                                  <div
                                    className="password-icon"
                                    onClick={() =>
                                      setShowPassword1(!showPassword1)
                                    }
                                  >
                                    {showPassword1 ? <FaEyeSlash /> : <FaEye />}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="addyour">
                              <div>
                                <label htmlFor="">
                                  Repeact Your New Password
                                </label>
                                <div className="password-input-container">
                                  <input
                                    className="input-box-1-acc"
                                    type={showPassword2? "text" : "password"}
                                    value={password2}
                                    onChange={(e) =>
                                      setPassword2(e.target.value)
                                    }
                                  />
                                  <div
                                    className="password-icon"
                                    onClick={() =>
                                      setShowPassword2(!showPassword2)
                                    }
                                  >
                                    {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                                  </div>
                                </div>
                                <div className="save-btn1-div">
                                  <button
                                    onClick={notify}
                                    className="save-acc-btn"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </TabPanel>
                      <TabPanel>
                        <div className="tab-2-delete-acc">
                          <div>
                            <h1 className="delete-h1-acc">Delete Account</h1>
                          </div>
                          <div className="acc-2-a">
                            <div className="acc-a1">
                              <span>
                                <IoIosInformationCircle />
                              </span>
                              <p>
                                Deleting your account will delete all of the
                                content you have created. It will be completely
                                irrecoverable.
                              </p>
                            </div>
                          </div>

                          <div className="checkbox-1-acc">
                            <input
                              type="checkbox"
                              id="my-checkbox"
                              class="custom-checkbox"
                            />
                            <label htmlFor="">
                              I understand the consequences.
                            </label>
                          </div>
                          <div>
                            <button
                                   onClick={notify2}
                            className="delete-acc-btn">
                              Delete Account
                            </button>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
