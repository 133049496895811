import React, { useState } from "react";
import "./Dashboard.css";
import Sidebar2 from "../../Features/Sidebar/Sidebar2";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { MdPhonelinkRing } from "react-icons/md";
const Dashboard = () => {

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div className="dashboard-bg">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="side-dash1">
            <div className="sidebar-home-d">
              <Sidebar2 />
            </div>
            <div className="main-y head-dash">
              {/* <div className="cards-dash-section1" >
            <div className="card-1-dash"> 
              <img src={require('../../Img/home1.jpg')} alt="" />

            </div>
            <div className="card-1-dash"> 
              <img src={require('../../Img/home2.jpg')} alt="" />

            </div>
            <div className="card-1-dash"> 
              <img src={require('../../Img/home1.jpg')} alt="" />

            </div>
            <div className="card-1-dash"> 
              <img src={require('../../Img/home2.jpg')} alt="" />

            </div>

          </div> */}

              <div className="dash-a1">
                <div className="dash-a2">
                  <div className="dash-logo-1">
                    <img src={require("../../Img/logo.png")} alt="" />
                  </div>
                  <div>
                    <h1 className="dash-h1">Coaching Results</h1>
                    <p className="dash-p1">In Last Two Years Gold,Silver,Bronze</p>
                  </div>
                </div>

                <div className="dash-a6">
                <h1>
                  Intro Video 
                </h1>
                </div>
                {/* Tabs */}
            <div className="dash-tabs">
                <Tabs>
    <TabList>
      <div className="dash-7">
      <Tab
     
      >
        <button
          onClick={() => toggleTab(1)}
        className={toggleState === 1 ? "dash-btn-1" : "dash-btn1"}
       >English</button>
        </Tab>
      <Tab
          
      ><button
      onClick={() => toggleTab(2)}
      className={toggleState === 2 ? "dash-btn-2" : "dash-btn2"}
    >Hindi</button></Tab>
      </div>
    </TabList>
                {/* Intro Video English */}

    <TabPanel>
    <div className="dash-a5">
                  <h1 className="dash-h2">Intro Video English</h1>
                <div className="dash-a4">
                <div className="dash-a3">
                <iframe
                className="i-f-dash-1"
                width="560" height="315" src="https://www.youtube.com/embed/wm3lp9bC5Kc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                </div>

                </div>
    </TabPanel>
                {/* Intro Video Hindi */}

    <TabPanel>
    <div className="dash-a5">
                  <h1 className="dash-h2">Intro Video Hindi</h1>
                <div className="dash-a4">
                <div className="dash-a3">
                <iframe
                className="i-f-dash-1"
                width="560" height="315" src="https://www.youtube.com/embed/wm3lp9bC5Kc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                </div>

                </div>
    </TabPanel>
  </Tabs>
                </div>
                {/* Pricing cards */}
                <div className="dash-15">
                   <h1 className="dash-h8">Fee Details</h1>

   <div class="wrapper1">
     
 
     <div class="table-1 Ultimate">
         <div class="price-section-1">
             <div class="price-area-1">
                 <div class="inner-area-1">
                     <span class="dash-text">
                        &#8377;
                     </span>
                     <span class="price">50000
                     </span>
                 </div>
             </div>
         </div>
         <div class="package-name">
             
         </div>
         <div class="features">
             <li>
                 <span class="list-name">All Existing Template</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">100% Responsive Design</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Credit Remove Permission</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Lifetime Template Updates</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <div class="btn"><button>Purchase</button></div>
         </div>
     </div>
     {/* card2 */}
     <div class="table-2 Ultimate">
         <div class="price-section-2">
             <div class="price-area-2">
                 <div class="inner-area-2">
                     <span class="dash-text">
                 $
                     </span>
                     <span class="price-2dash">750
                     </span>
                 </div>
             </div>
         </div>
         <div class="package-name-2">
             
         </div>
         <div class="features-2">
             <li>
                 <span class="list-name">All Existing Template</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">100% Responsive Design</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Credit Remove Permission</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Lifetime Template Updates</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <div class="btn"><button>Purchase</button></div>
         </div>
     </div>
     
 </div>

   
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
