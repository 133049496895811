import { signOut } from 'firebase/auth';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Firebase/Firebase';

const Logout123 = () => {
    const navigate = useNavigate();

    useEffect(() => { 
            signOut(auth);
            navigate("/login");
    },[])
    



  return (
    <div></div>
  )
}

export default Logout123