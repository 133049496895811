import { collection, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../Firebase/Firebase';
import "./LiveS.css";
import Streadata from '../../Features/Streadata/Streadata'
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
const LiveS = () => {
  const [streams, setstreams] = useState({});
  const Name = streams.id;
  const [Stream, setStream] = useState();
  useEffect(() => {
    const todosref = collection(db, "StreamVideos");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Stream = [];
      querySnapshot.forEach((doc) => {
        Stream.push({
          id: doc.id,
          ...doc.data(),
        });
        setStream(Stream);
      });
    });

    return () => unsub();
  }, []);
  return (
    <div className="body-fix-h bg-stream">
      <div className="live-s-f wrapper">
        <div>
          <Sidebar1 />
        </div>

        <div className="live-head-1 main-y">
          <div className="live-head-2">
            <div className="live-head-3">
              <div className="live-head-h">
                <h1>Live</h1>
              </div>
              <div className="live-head-p">
                <p>
                  Next live stream will be announced shortly, look out for the
                  push notification!.
                </p>
              </div>
            </div>
          </div>
       {/* <Streadata/> */}
       {Stream?.map((Streaming) => (
                    <>
                      <Streadata streams={Streaming} />
                    </>
                  ))}
        </div>
      </div>
    </div>
  );
};

export default LiveS;
