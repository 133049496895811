import React, { useState } from "react";
import "./Forums.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { FaUserCircle } from "react-icons/fa";
import { BsFillCameraFill, BsFillCameraVideoFill, BsFillReplyFill } from "react-icons/bs";
import { Navigate, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { FileUploader } from "react-drag-drop-files";
import {toast} from 'react-hot-toast'

const Forums = () => {

  const notify = () => toast.success('Post Successfully.');


  function firstClick() {
    // add another onclick funcion 
  
      // logInWithEmailAndPassword(email, password).then(() =>
      //   navigate("/Home")
      // )
      notify()
  }
  
  
const fileTypes = ["JPEG", "PNG"];
const [file, setFile] = useState(null);
const handleChange = (file) => {
  setFile(file);
};
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();

  return (
    <div >
      <div className="bg-formus">
        <div className="body-fix-h">
<div className="wrapper ">

        <div className="flex-sid-forms">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="main-y">

          <div className="forums-data1">
            <div className="forums-data">
              <form>
                <input
                  placeholder="Search"
                  type="search"
                  className="search-input-forums"
                  name=""
                  id=""
                />
              </form>

              <div className="border-jh">
                <div className="sub-sc">
                  <h1 className="all-h1">All Discussions</h1>
                  <div className="btn-ss">
                    <button className="Subscribe-btn-1">Subscribe</button>
                    <button onClick={openModal} className="New-discussion-btn1">
                      New discussion{" "}
                    </button>
                  </div>
                </div>
                <hr />

                <div className="main-comments-div">
                  <div>
                    <div>
                      <div className="comment-1">
                        <div className="comm-gf">
                          <FaUserCircle className="svg-user" />

                          <div className="updates-time-rep">
                            <h1
                              className="h-h1"
                              onClick={() => navigate("/Reply")}
                            >
                              Female blood sugar while menses?
                            </h1>
                            <div className="span-updates">
                              <span className="hov-span1">
                                <span>
                                  <BsFillReplyFill
                                    onClick={() => navigate("/Reply")}
                                    className="svg-fill"
                                  />
                                </span>
                                <span onClick={() => navigate("/Reply")}>
                                  SAMSON
                                </span>
                              </span>
                              <span>replied</span>
                              <span
                                className="hov-span1"
                                onClick={() => navigate("/Reply")}
                              >
                                3 hours, 33 minutes ago
                              </span>
                              <span> 2 Members </span>
                              <span>· 2 Replies</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="comment-1">
                        <div className="comm-gf">
                          <FaUserCircle className="svg-user" />

                          <div className="updates-time-rep">
                            <h1
                              className="h-h1"
                              onClick={() => navigate("/Reply")}
                            >
                              Female blood sugar while menses?
                            </h1>
                            <div className="span-updates">
                              <span className="hov-span1">
                                <span>
                                  <BsFillReplyFill
                                    onClick={() => navigate("/Reply")}
                                    className="svg-fill"
                                  />
                                </span>
                                <span onClick={() => navigate("/Reply")}>
                                  SAMSON
                                </span>
                              </span>
                              <span>replied</span>
                              <span
                                className="hov-span1"
                                onClick={() => navigate("/Reply")}
                              >
                                3 hours, 33 minutes ago
                              </span>
                              <span> 2 Members </span>
                              <span>· 2 Replies</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="comment-1">
                        <div className="comm-gf">
                          <FaUserCircle className="svg-user" />

                          <div className="updates-time-rep">
                            <h1
                              className="h-h1"
                              onClick={() => navigate("/Reply")}
                            >
                              Female blood sugar while menses?
                            </h1>
                            <div className="span-updates">
                              <span className="hov-span1">
                                <span>
                                  <BsFillReplyFill
                                    onClick={() => navigate("/Reply")}
                                    className="svg-fill"
                                  />
                                </span>
                                <span onClick={() => navigate("/Reply")}>
                                  SAMSON
                                </span>
                              </span>
                              <span>replied</span>
                              <span
                                className="hov-span1"
                                onClick={() => navigate("/Reply")}
                              >
                                3 hours, 33 minutes ago
                              </span>
                              <span> 2 Members </span>
                              <span>· 2 Replies</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="comment-1">
                        <div className="comm-gf">
                          <FaUserCircle className="svg-user" />

                          <div className="updates-time-rep">
                            <h1
                              className="h-h1"
                              onClick={() => navigate("/Reply")}
                            >
                              Female blood sugar while menses?
                            </h1>
                            <div className="span-updates">
                              <span className="hov-span1">
                                <span>
                                  <BsFillReplyFill
                                    onClick={() => navigate("/Reply")}
                                    className="svg-fill"
                                  />
                                </span>
                                <span onClick={() => navigate("/Reply")}>
                                  SAMSON
                                </span>
                              </span>
                              <span>replied</span>
                              <span
                                className="hov-span1"
                                onClick={() => navigate("/Reply")}
                              >
                                3 hours, 33 minutes ago
                              </span>
                              <span> 2 Members </span>
                              <span>· 2 Replies</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="comment-1">
                        <div className="comm-gf">
                          <FaUserCircle className="svg-user" />

                          <div className="updates-time-rep">
                            <h1
                              className="h-h1"
                              onClick={() => navigate("/Reply")}
                            >
                              Female blood sugar while menses?
                            </h1>
                            <div className="span-updates">
                              <span className="hov-span1">
                                <span>
                                  <BsFillReplyFill
                                    onClick={() => navigate("/Reply")}
                                    className="svg-fill"
                                  />
                                </span>
                                <span onClick={() => navigate("/Reply")}>
                                  SAMSON
                                </span>
                              </span>
                              <span>replied</span>
                              <span
                                className="hov-span1"
                                onClick={() => navigate("/Reply")}
                              >
                                3 hours, 33 minutes ago
                              </span>
                              <span> 2 Members </span>
                              <span>· 2 Replies</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="comment-1">
                        <div className="comm-gf">
                          <FaUserCircle className="svg-user" />

                          <div className="updates-time-rep">
                            <h1
                              className="h-h1"
                              onClick={() => navigate("/Reply")}
                            >
                              Female blood sugar while menses?
                            </h1>
                            <div className="span-updates">
                              <span className="hov-span1">
                                <span>
                                  <BsFillReplyFill
                                    onClick={() => navigate("/Reply")}
                                    className="svg-fill"
                                  />
                                </span>
                                <span onClick={() => navigate("/Reply")}>
                                  SAMSON
                                </span>
                              </span>
                              <span>replied</span>
                              <span
                                className="hov-span1"
                                onClick={() => navigate("/Reply")}
                              >
                                3 hours, 33 minutes ago
                              </span>
                              <span> 2 Members </span>
                              <span>· 2 Replies</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
</div>

        </div>
        </div>
        </div>


      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button> */}

    
          <div className="header-modal-s">
            <div className="user-dp-modal-reply">
              <FaUserCircle />
            </div>
            <div className="search-input">
              <input
                placeholder="Discussion Title"
                type="search"
                name=""
                id=""
              />
            </div>

            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal} />
            </div>
          </div>
      
          <hr />
          {/* textarea-modal */}
          <form >

          
          <div className="modal-text-area-data-1">
            <div className="text-area-modal-s">
              <textarea 
              className="text-modal-area1"
              placeholder="Type your discussion content here"
              name="" id="" cols="30" rows="10"></textarea>
            </div>
            <div className="upload-options">
              {/* <input type="file" name="" id="" /> */}
              <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      />
{/* <BsFillCameraFill/>
<BsFillCameraVideoFill/> */}

            </div>
         
          </div>
          <hr />
          <div
          className="section-1-text"
          >
          <textarea 
          placeholder="Type one or more tag, comma separated "
          className="text-area-modal-2"
          name="" id="" cols="30" rows="10"></textarea>
          </div>
          <hr />
          <div className="check-box-post-btn">
           <div>
           <input type="checkbox" id="html" name="fav_language" value="HTML"/>
  <label >Notify me of replies via emailL</label>
           </div>
           <div>
            <button 
               onClick={() => {firstClick(); }}
            className="post-btn-dis">Post</button>
           </div>

          </div>
          
          </form>
      
      </Modal>
    </div>
  );
};

export default Forums;
