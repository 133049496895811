import React, { useState } from "react";
import Slider1 from "../../Features/Sidebar/Sidebar1";
import "./Episode.css";
import ReactAudioPlayer from "react-audio-player";
import { FaUserCircle } from "react-icons/fa";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../../Style.scss";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {toast} from 'react-hot-toast'

const Episode = () => {

  const notify = () => toast.success('Publish Successfully.');


  function firstClick() {
    // add another onclick funcion 
  
      // logInWithEmailAndPassword(email, password).then(() =>
      //   navigate("/Home")
      // )
      notify()
  }
  

  // function handleClick() {
  //   window.scrollTo(0,0);
  // }
const navigate=useNavigate();


  return (
    <div className="bg-blog body-fix-h">
      <div className=" wrapper">
        <div>
          <Slider1 />
        </div>
        <div className="main-y episode-p-h-1 ">
 
          <div className="episode-p-h-2">
            <div>
              <div>
              <div class="tool-b" >
              <button class="tooltip previous-btn" data-tool="Previous"><IoIosArrowBack/></button>
              <button class="tooltip next-btn" data-tool="Next"><IoIosArrowForward/></button>

</div>
                <button>Next</button>

              </div>
              <div className="episode-p-h-3">
                <h1>EPISODE 1: 2022 OLYMPIA REVIEW</h1>
              </div>
              <div className="episode-iframe">
                <iframe
                  title="Testing"
                  allowtransparency="true"
                  height="150"
                  width="100%"
                  style={{ border: "none", minWidth: "min(100%, 430px)" }}
                  scrolling="no"
                  data-name="pb-iframe-player"
                  controlsList="nodownload"
                  src="https://www.podbean.com/player-v2/?i=gyvgk-1365f9e-pb&from=pb6admin&share=1&download=1&rtl=0&fonts=Arial&skin=1&font-color=auto&logo_link=episode_page&btn-skin=7"
                ></iframe>
                <div className="iframe-audio-p1">
                  <p>
                    In this episode, John & Luke review the Olympia, their
                    favorite moments, disappointments, and the issues they saw
                    across competitors.{" "}
                  </p>
                </div>
                <div className="iframe-audio-p1">
                  <p>Timestamps:</p>
                </div>
                <div className="iframe-audio-p1">
                  <p>Intro 00:20</p>
                </div>
                <div className="iframe-audio-p1">
                  <p>Open Bodybuilding 01:54</p>
                </div>
                <div className="iframe-audio-p1">
                  <p> Favorite and Disappointing Placements: 11:00</p>
                </div>
                <div className="iframe-audio-p1">
                  <p>Mistakes we Saw 18:42</p>
                </div>
                <div className="iframe-audio-p1">
                  <p>Closing Thoughts 48:17</p>
                </div>
                <hr />
                <div className="swiper-data1">
                  <div className="swiper-data">
                    <Swiper
                      // install Swiper modules
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={50}
                      slidesPerView={3}
                      centeredSlides={true}
                      loop={true}
                      navigation
                      // pagination={{ clickable: true }}

                      onSwiper={(swiper) => console.log(swiper)}
                      onSlideChange={() => console.log("slide change")}
                    >
                      <SwiperSlide class="swiper-slide">
                        <div className="swiper-border-1">
                          <h1>
                            Episode 64: Alex Kikel Managing Insulin in the
                            Enhanced Competitor
                          </h1>
                          <p>
                            In this episode, Alex Kikel joins John and Luke to
                            discuss all things insulin in the enhanced
                            competitor, why it’s important to understand, and
                            how…
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide class="swiper-slide">
                      <div className="swiper-border-1">
                          <h1>
                            Episode 64: Alex Kikel Managing Insulin in the
                            Enhanced Competitor
                          </h1>
                          <p>
                            In this episode, Alex Kikel joins John and Luke to
                            discuss all things insulin in the enhanced
                            competitor, why it’s important to understand, and
                            how…
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide class="swiper-slide">
                      <div className="swiper-border-1">
                          <h1>
                            Episode 64: Alex Kikel Managing Insulin in the
                            Enhanced Competitor
                          </h1>
                          <p>
                            In this episode, Alex Kikel joins John and Luke to
                            discuss all things insulin in the enhanced
                            competitor, why it’s important to understand, and
                            how…
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide class="swiper-slide">
                      <div className="swiper-border-1">
                          <h1>
                            Episode 64: Alex Kikel Managing Insulin in the
                            Enhanced Competitor
                          </h1>
                          <p>
                            In this episode, Alex Kikel joins John and Luke to
                            discuss all things insulin in the enhanced
                            competitor, why it’s important to understand, and
                            how…
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide class="swiper-slide">
                      <div className="swiper-border-1">
                          <h1>
                            Episode 64: Alex Kikel Managing Insulin in the
                            Enhanced Competitor
                          </h1>
                          <p>
                            In this episode, Alex Kikel joins John and Luke to
                            discuss all things insulin in the enhanced
                            competitor, why it’s important to understand, and
                            how…
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide class="swiper-slide">
                      <div className="swiper-border-1">
                          <h1 onClick={ ()=> navigate('/Vlog')}>
                            Episode 64: Alex Kikel Managing Insulin in the
                            Enhanced Competitor
                          </h1>
                          <p>
                            In this episode, Alex Kikel joins John and Luke to
                            discuss all things insulin in the enhanced
                            competitor, why it’s important to understand, and
                            how…
                          </p>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                {/* <button onClick={handleClick}>Scroll to Top</button> */}
                <hr />
                <div className="iframe-audio-p1">
                  <h1>Responses</h1>
                </div>
                <div className="publish-comments-">
                  <div className="form-text-area-episode">
                    <form>
                      <div className="user-name-episode">
                        <FaUserCircle />
                        <h1>Ranjan</h1>
                      </div>
                      <textarea
                        placeholder="Write a response..."
                        className="text-area1"
                        id="message"
                        name="message"
                      ></textarea>
                      <div className="input-submit-episode">
                        <button 
                        
               onClick={() => {firstClick(); }}
                        
                        className="publish-btn-episodes">
                          Publish
                        </button>
                        
                      </div>
                    </form>

                  </div>
                  
                
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
    </div>
  );
};

export default Episode;
