import { Outlet, Navigate} from "react-router-dom";
import { auth } from "../Firebase/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import ScrollToTop from "../Scrollontop";

import Navbar2 from "../Features/Navbar/Navbar2";

const LocalRoutes = () => {




  const authUser = useAuthState(auth);

  if (authUser === undefined) {
    return null; // or loading spinner, etc...
  }


  return authUser ? (
    <>
      <ScrollToTop />

      <Navbar2/>

      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default LocalRoutes;
