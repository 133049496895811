import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './Signup.css'
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  return (
    <div className='sig-bg'>
      <div className='log-form-p1'>
        <div className='logo-pp51'>
          <img src={require('../../Img/logo.png')} alt="" />
        </div>
        <h1 className='log-h11'>SIGN UP</h1>
        <div className='log-form'> 
          <form>
            <div className='log-fgg1'>
            <input className='input-log1' type="name"
              placeholder='Enter your name'
              name="email" id="" />
              <input className='input-log1' type="email"
              placeholder='Email'
              name="email" id="" />
          <div className="passworld-hide-show1">

<input
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  className="input-log"
  placeholder="Password"
  type={showPassword ? "text" : "password"}
  
  
  name=""
  id=""
  />
    <div
                  className="password-icon3"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
  </div>
<button
          onClick={()=> navigate('/')}
          className="log-bb11">SIGN UP</button>
              
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Signup