import React, { useEffect, useState } from "react";
import "../../Features/Sidebar/Sidenav.scss";
import "./Studydetails.css";
// import { StyledOffCanvas, Menu, Overlay } from "styled-off-canvas";
import styled from "styled-components";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { auth } from "../../Firebase/Firebase";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ChatApp from "../../Features/ChatApp/ChatApp";
import { FiMenu } from "react-icons/fi";
import "../../Features/Sidebar/Sidebar.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { AiFillCheckCircle, AiOutlineClose } from "react-icons/ai";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { GrMenu } from "react-icons/gr";

// import "react-tabs/style/react-tabs.css";
const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 100;
  color: black;
  transition: left 0.3s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Studydetails = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  const log = () => {
    // toast("Added Successfully");
    signOut(auth);
    // alert('Login')
    navigate("/login");
  };

  return (
    <div className="bg-blog">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="fle-main-div">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y">
              <div className="tabs-1">
                {/* <Tabs>
            <TabList> */}
                <div>
                  <StyledOffCanvas isOpen={isOpen}>
                    <div className="head-side-modules">
                      <div className="close-div-svg">
                        <AiOutlineClose
                          className="svg-side close-svg1"
                          onClick={() => setIsOpen(!isOpen)}
                        />
                      </div>
                      <div className="sidebar-a1">
                        <div className="head-h">
                          <img src={require("../../Img/logo.png")} alt="" />
                        </div>
                        <div className="progress-bar-a1">
                          <div class="progress-Modules">
                            <div class="progress-value-Modules"></div>
                          </div>
                          <div className="side-mod3">
                            <div>
                              <h1 className="side-mod1">41% Complete</h1>
                            </div>
                            <div>
                              <h1 className="side-mod2">31/71</h1>
                            </div>
                          </div>
                          <hr />
                          <div className="module-title-2">
                            {/* Module 1 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                  MODULE 1 - INTRODUCTION
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    WHY WE STARTED{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    WHAT YOUR PURPOSE
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    PHYSQUE ASSESSMENT{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    TAKING THE RIGHT STEP (bulk, cut and
                                    maintenance and yes & no in ped’s)
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 2 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                  {" "}
                                  MODULE 2 - NUTRITION MODULE
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    CALORIES for SHREDDING AND MASSING UP
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    PROTEIN{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    CARBOHYDRATE
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    FATS
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    GI TRACT FUNCTIONS AND ENERGY DELIVERY
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    ELECTROLYTES AND FLUID BALANCE{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    IMPORTANCE OF MICRONUTRIENTS{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    NUTRITION AROUND TRAINING and NUTRIENT
                                    TIMING{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    SUPPLEMENTATION{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    REFEEDS AND CHEAT MEALS{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 3 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                  {" "}
                                  MODULE 3 - TRAINING
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    MAIN DRIVER OF HYPERTROPHY{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    Need and analysis{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    VOLUME, INTESITY AND FREQUENCY
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    Basic concepts of hypertrophy
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Basic concepts of hypertrophy{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    EXERCISE SELECTION{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    IMPROVING A LAGGING BODY PART{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    TRAINING PROGRESSION{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    FATUGUE MANAGEMENT{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    INSIGHTS OF SOME POPULAR TRAINING METHODS
                                    AND THEIR IMPLEMENTATION{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    TRAINING AROUND INJURIES
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 4 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                  {" "}
                                  MODULE 4 - RECOVERY TOOLS
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    UNDERSTANDING WHY IT’S THE MOST IMPORTANT
                                    FACTOR{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    PHYSIOTHERAPY TOOLS{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    SLEEP
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    MANAGING PSYCHOLOGICAL STRESS, TRAINING
                                    STRESS AND REST{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    I.V. INFUSIONS
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    DELOAD, TAPER and REST DAYS IN TRAINING{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 5 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                  {" "}
                                  MODULE 5 - ANALYSING THE ORGAN HEALTH AND LAB
                                  MARKERS
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    MONITERING THE HEALTH RISKS{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    HEART HEALTH{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    LIVER HEALTH
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    KIDNEY HEATH
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    MANAGING BLOOD PRESSURE AND BLOOD GLUCOSE5.
                                    MANAGING BLOOD PRESSURE AND BLOOD GLUCOSE
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    MANAGING THE SIDE EFFECTS POST CYCLE or ON
                                    CYCLE{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    DELOAD, TAPER and REST DAYS IN TRAINING{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 6 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                       
                                  MODULE 6 - PERFORMANCE ENHANCING DRUGS
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Testosterone{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                               
                                    DHT derivative anabolics
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    19-NOR derivative anabolics
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Other categories{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Growth hormone & Peptides{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Insulin
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    PED Periodization{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Estrogen and prolactin management{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    PED’s for women
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 7 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                  {" "}
                                  MODULE 7 – OFF-SEASON
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    When to start and how long should be the
                                    off-seasonn{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    Nutrition periodization{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    Training periodization{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Managing insulin sensitivity, gut health and
                                    appetite{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Essential supplement{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Choosing the right off-season PED’s and
                                    progression with PED’s
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Controlling the body fat levels
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Cardiovascular health during off-season
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 8 */}
                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                  {" "}
                                  MODULE 8 – CONTEST PREP
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Nutrition periodization during prep{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    Training periodization{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    Contest prep supplementation{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    Managing fatigue during prep{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    {" "}
                                    PED’s , selection and dose optimization{" "}
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Module 9 */}

                            <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                MODULE 9 – GETTING READY FOR THE STAGE 
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                           PEAK WEEK 
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    PRESENTATION – POSING ROUTINE AND COSTUME SELECTION 
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                    DAY BEFORE SHOW DAY
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                               SHOW DAY
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                             
                            </div>
                                 {/* Module 10 */}

                                 <div className="module-side-a2">
                              <div>
                                <h1 className="side-m-h1">
                                MODULE 10 – POST SHOW   
                                </h1>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                       	Rebound
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                           Blood work
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                                  Planning a PCT
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                           	A complete off
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                       Planning the next show
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                    Diet planning post show
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
                    Diet planning post show
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                              <div className="module-side-a1">
                                <div>
                                  <h1
                                    onClick={() => navigate("/Modules/Lessons")}
                                    className="module-side-h1"
                                  >
           PED framework (starting with a base)
                                  </h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#bc0000" }}
                                  />
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </StyledOffCanvas>
                  <StyledOffCanvasOverlay
                    isOpen={isOpen}
                    onClick={() => setIsOpen(false)}
                  />
                </div>

                {/* </TabList> */}
                <div className="menu-modules">
                  <FiMenu
                    className="svg-open svg-side"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                </div>
                {/* <TabPanel> */}
                <div className="paf-g tab-panel-i">
                  <div className="tab-oi">
                    <div>
                      <h1 className="intro-o">MODULE 1 - INTRODUCTION</h1>
                    </div>

                    <div className="blo-int">
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Esse similique earum quos modi nostrum dolor
                        aliquam numquam iure, ex neque voluptas debitis,
                        voluptatibus expedita error vel veniam placeat eos quas.
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Accusantium corrupti deserunt aliquam suscipit
                        quod autem quae quibusdam illum. Est similique
                        laboriosam id aliquam consectetur assumenda doloremque
                        tempora soluta quaerat eligendi.
                      </p>
                      <div className="i-frame-o">
                        <iframe
                          style={{ width: "100%", height: "50vh" }}
                          src="https://player.vimeo.com/video/787104299?h=a5dd35f9b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          width="1280"
                          height="720"
                          frameborder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowfullscreen
                          title="Why We Started English"
                        ></iframe>
                      </div>
                    </div>

                    <div className="lessons">
                      <div className="lessons-a1">
                        <h1>HAVE A QUESTION ON THE MATERIAL:</h1>
                      </div>
                      <div className="lessons-a2">
                        <h1>
                          Head to the{" "}
                          <span
                            onClick={() => navigate("/Forums")}
                            className="span-1-lessons"
                          >
                            forums
                          </span>{" "}
                          HERE and ask John a question
                        </h1>
                      </div>
                      {/* <div className="lessons-a3">
                          <h1> WANT TO SEE THE MATERIAL APPLIED:</h1>
                        </div> */}
                      {/* <div className="lessons-a4">
                          Head the Athlete Case Studies to see the education in
                          action
                        </div> */}
                    </div>
                  </div>
                </div>
                {/* </TabPanel> */}
                {/* </Tabs> */}

                <ChatApp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Studydetails;
