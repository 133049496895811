import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { BsChatSquareText, BsFillCameraVideoFill } from "react-icons/bs";
import { CiStreamOn } from "react-icons/ci";
import { FaBookmark, FaUserCircle } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { TbListDetails } from "react-icons/tb";

import { HiUserCircle } from "react-icons/hi";
import { AiOutlineClose, AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { BsFillGearFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosNotifications, IoMdContacts } from "react-icons/io";
import { GrLogout } from "react-icons/gr";
import { BiMessageAltDetail } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import styled from 'styled-components';
import {toast} from 'react-hot-toast'

const StyledOffCanvas = styled.aside`
position: fixed;
top: 0;
left: ${props => (props.isOpen ? 0 : '-100%')};
width:300px;
height: 100%;
background-color:#bc0000;
z-index: 1;
color:white;
transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
z-index: 0;
display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const Navbar = () => {

  const notify = () => toast.success('Logout Successfully.');

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  let activeStyle = {
    // textDecoration: "underline",
    borderBottom: "2px solid white",
    // padding: "2px",
    // textunderlineOffset: '8px',
  };

  return (
    <div className="nav-bg">
      <div className="fix-pro-nav">
        <div className="fle1-nav">
          <div className="logo-1">
            <img
              onClick={() => navigate("/Home")}
              className="nav-logo"
              src={require("../../Img/logo.png")}
              alt=""
            />
          </div>


          <div className="nav-bb">
            <div class="bodys">
              <details class="dropdown">
                <summary role="button">
                  <a class="button">
                    Ranjan <IoIosArrowDown/>  <FaUserCircle style={{ fontSize: "30px" }} />
                  </a>
                </summary>
                <ul>
                  <li>
                    <Link className="gap-svg-h" to={"/User"}>
                      <div>
                        <CgProfile />
                      </div>
                      <span>Ranjan @bodybuilding</span>
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h" to={"/UserPlan"}>
                      My Plan
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h" to={"/User"}>
                      <CgProfile />
                      Profile
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h" to={"/UserAccount"}>
                      <BsFillGearFill />
                      Account
                    </Link>
                  </li>
                  {/* <li className="gap-sv">
                    <Link className="gap-svg-h">
                      <IoIosNotifications />
                      Notification
                    </Link>
                  </li> */}
                  <li className="gap-sv">
                    <Link className="gap-svg-h" to={"/Forums"}>
                      <BiMessageAltDetail />
                      Forums
                    </Link>
                  </li>
                  <li
                   onClick={notify}
                  className="gap-sv">
                    <Link to={"/"} className="gap-svg-h">
                      <MdLogout />
                      Log Out
                    </Link>
                  </li>
                </ul>
              </details>
            </div>
            {/* <button onClick={() => navigate("/")} className="login-b">
              LOG IN
            </button> */}
            {/* <button onClick={() => navigate("/SignUp")} className="sign-bb">
              SIGN UP
            </button> */}

            {/* mobile-menu */}
            <div className="mobile-menu-nav">
            <button onClick={() => setIsOpen(!isOpen)}>
                  <GrMenu className="open-tab-mob" />
                </button>
            <StyledOffCanvas isOpen={isOpen}>
      <div className="head-mobile-menu">
                    <ul className="ul-menu-mobile">
                      <li>
                        <div className="mobile-k-m">
                          <div className="user-name-mobile-menu">
                            <HiUserCircle/>
                            <div className="user-m">
                              <h1>User !</h1>
                              <h1>My Account</h1>
                            </div>
                          </div>
                          <div>
                            <a onClick={() => setIsOpen(false)}>
                              <AiOutlineClose className="close-mobile-menu" />
                            </a>
                          </div>
                        </div>
                      </li>
                  
                      <li>
                        <div className="side-menu-h1">
                        <AiOutlineHome/>
                          <Link to="/Home">
                            <span className="link-k">HOME</span>
                          </Link>
                        </div>
                      </li>
                      
                      <li>
                        <div className="side-menu-h1">
                          <ImBooks/>
                          <Link to="/Studydetails">
                            <span className="link-k">STUDY DETAILS</span>
                          </Link>
                        </div>
                      </li>

                      <li>
                        <div className="side-menu-h1">
                          <BsChatSquareText/>
                          <Link to="/Forums">
                            <span className="link-k">FORUMS</span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="side-menu-h1">
                          <CiStreamOn/>
                          <Link to="/LiveStream">
                            <span className="link-k">LIVE STREAM</span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="side-menu-h1">
                          <TbListDetails/>
                          <Link to="/Course">
                            <span className="link-k">COURSE DETAILS</span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="side-menu-h1">
                          <FaBookmark/>
                          <Link to="/Aboutus">
                            <span className="link-k">ABOUT US</span>
                          </Link>
                        </div>


                      </li>
                      <li>
                        <div className="side-menu-h1">
                        <BsFillCameraVideoFill/>
                          <Link to="/Vlog">
                            <span className="link-k">VLOG</span>
                          </Link>
                        </div>


                      </li>
                      <li>
                        <div className="side-menu-h1">
                        <IoMdContacts/>
                          <Link to="/Contact2">
                            <span className="link-k">Contact Us</span>
                          </Link>
                        </div>


                      </li>
                  
                     
                    </ul>
                  </div>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
            </div>
            
          <div className="ul-n">
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to={"/Home"}
              className="nav-a"
            >
              Home
            </NavLink>
          </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Navbar;
