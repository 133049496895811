import React from 'react'
import { useParams } from 'react-router-dom'


const Test2 = () => {
    
  const {id} = useParams()

  return (
    <div style={{padding:'100px 0px 0px 0px'}}>
      <h1>{id}</h1>
    </div>
  )
}

export default Test2