import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-hh">
        <div className="body-fix-h">
        <div className="wrapper ">

      <div className="home-side-bar-data">
        <div className="sidebar-home-d">
          <Sidebar1 />
        </div>
        <div className="main-y">

        <div className="data-div-h">
          <div className="data-container">
            <div className="update-1">
              <h1>
                Female Module now live, CLICK HERE to find out more and
                purchase.
              </h1>
            </div>
            <div className="update-1">
              <h1>
                Next live stream will be announced shortly, look out for the
                push notification!.
              </h1>
            </div>
            <div className="h1-courses">
              <h1>COURSES</h1>
            </div>

            <div className="card-head-course">
              <div className="card-head-data-j">
                <div>
                  <img
                    className="ww"
                    src={require("../../Img/home2.jpg")}
                    alt=""
                    onClick={() => navigate("/Modules")}
                  />
                </div>
                <div className="card-h-certifi">
                  <h1>Contest Prep Level 1 Certification</h1>
                </div>
                <div className="bodd-d">
                  <div class="progress">
                    <div class="progress-value"></div>
                  </div>
                </div>
                <div>
                  <h1 className="percentage-course-com">41% Complete</h1>
                </div>
                <div>
                  <button
                    onClick={() => navigate("/Modules")}
                    className="view-btn-hom"
                  >
                    View Lessons{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

      </div>
    </div>
    </div>
    </div>


  );
};

export default Home;
