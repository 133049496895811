import React from 'react'
import './Aboutus.css'
import Sidebar2 from "../../Features/Sidebar/Sidebar2";

const Aboutus = () => {
  return (
    <div className='bg-about'>
  <div className=" body-fix-h">
        <div className="wrapper ">
        <div className="sidebar-home-d">
            <Sidebar2/>
          </div>
      <div className='head-about main-y'>


      <h1 className='about-h1'>About Us</h1>
      <div className='w-o'>
<div className='w-oo'>

      <div>
        <p className='about-pp2' >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quisquam in ab omnis perspiciatis sed ipsam iste harum aut repellendus tempora facilis, amet esse quaerat nobis! Esse fugiat odit earum? lorem Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium esse rem corporis sapiente, nostrum eveniet at sed architecto maiores excepturi quod iusto accusantium quam doloribus consectetur nisi possimus, est praesentium!
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quisquam in ab omnis perspiciatis sed ipsam iste harum aut repellendus tempora facilis, amet esse quaerat nobis! Esse fugiat odit earum? lorem Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium esse rem corporis sapiente, nostrum eveniet at sed architecto maiores excepturi quod iusto accusantium quam doloribus consectetur nisi possimus, est praesentium!
        </p>
      </div>

      <div className='para-about2'>
        <p className='about-pp2' >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quisquam in ab omnis perspiciatis sed ipsam iste harum aut repellendus tempora facilis, amet esse quaerat nobis! Esse fugiat odit earum? lorem Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium esse rem corporis sapiente, nostrum eveniet at sed architecto maiores excepturi quod iusto accusantium quam doloribus consectetur nisi possimus, est praesentium!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quisquam in ab omnis perspiciatis sed ipsam iste harum aut repellendus tempora facilis, amet esse quaerat nobis! Esse fugiat odit earum? lorem Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium esse rem corporis sapiente, nostrum eveniet at sed architecto maiores excepturi quod iusto accusantium quam doloribus consectetur nisi possimus, est praesentium!</p>
      </div>
    </div>
</div>
      </div>
      </div>
      </div>
      </div>
  )
}

export default Aboutus