
import React from 'react'

import './Streadata.css'
const Streadata = ({ streams }) =>{
  const Name = streams.id;

  return (
    <div className="stream-comtainer">
    <div className="stream-videos">
      {/* Stream Videos */}

      <div class="card-container-stream">
      <div class="stream-card ">
          <video
            width="560"
            height="315"
            src={streams.Video}

            controlsList="nodownload"
            controls
          ></video>
          <div className="description-cards-stream">
            <h3>{streams.Title}</h3>
            <p>Card Description</p>
          </div>
        </div>
        {/* <div class="stream-card">
          <video
            width="560"
            height="315"
            src={require("../../Videos/More FOLLOWERS and Less MONEY _ Reasons _ Solution _ The Alchemist.mp4")}
            controlsList="nodownload"
            controls
          ></video>
          <div className="description-cards-stream">
            <h3>Card Title</h3>
            <p>Card Description</p>
          </div>
        </div>
        <div class="stream-card">
          <video
            width="560"
            height="315"
            src={require("../../Videos/More FOLLOWERS and Less MONEY _ Reasons _ Solution _ The Alchemist.mp4")}
            controlsList="nodownload"
            controls
          ></video>
          <div className="description-cards-stream">
            <h3>Card Title</h3>
            <p>Card Description</p>
          </div>
        </div>
        <div class="stream-card">
          <video
            width="560"
            height="315"
            src={require("../../Videos/More FOLLOWERS and Less MONEY _ Reasons _ Solution _ The Alchemist.mp4")}
            controlsList="nodownload"
            controls
          ></video>
          <div className="description-cards-stream">
            <h3>Card Title</h3>
            <p>Card Description</p>
          </div>
        </div>
        <div class="stream-card">
          <video
            width="560"
            height="315"
            src={require("../../Videos/More FOLLOWERS and Less MONEY _ Reasons _ Solution _ The Alchemist.mp4")}
            controlsList="nodownload"
            controls
          ></video>
          <div className="description-cards-stream">
            <h3>Card Title</h3>
            <p>Card Description</p>
          </div>
        </div> */}
      </div>
    </div>
  </div>
  )
}

export default Streadata