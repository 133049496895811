import { increment } from "firebase/firestore";
import React, { useState } from "react";
import "./Contact.css";
import {toast} from 'react-hot-toast'
import Sidebar2 from "../../Features/Sidebar/Sidebar2";

const Contact2 = () => {


  const notify = () => toast.success('Form Successfully Submit.');


  return (
    <div className="bg-contact">
            <div className="body-fix-h">
<div className="wrapper ">
<div className="sidebar-home-d">
            <Sidebar2 />
          </div>

      <div className="contact-section1 main-y">
        
    

      <div className="head-pp">
        <div className="contact-section2">
        {/* <h1 className="contact-h1">Feel Free To Contact Us! </h1> */}
        <div style={{textAlign:'center'}}>
{/* 
        <h1 className="contact-h2">+91 9876543210 </h1>
        <h1 className="contact-h3">example@gmail.com</h1> */}
        </div>
        </div>
     
      <div className="contact-us-form">
      <div class="login-box">
  <h2>Contact Us</h2>
   
  <form>
    <div class="user-box">
      <input type="text" name="" required/>
      <label>Name</label>
    </div>
    <div class="user-box">
      <input type="email" name="" required/>
      <label>Enter Address  </label>
    </div>
    <div class="user-box">
      <input type="number" name="" required/>
      <label>Contact No</label>
    </div>
    <div class="user-box">
    <textarea name="" id=""
    placeholder="Write Your Message Here"
    required></textarea>
   
    </div>
    <div className="div-btn-sub">
    <button  
     onClick={notify}
    className="submit-btn-contact">

      Submit
    </button>
    </div>
  
  </form>
</div>
      </div>
      </div>
    </div>
      </div>
      </div>
      </div>
    
    


  );
};

export default Contact2;
