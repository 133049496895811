import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logInWithEmailAndPassword } from "../../Firebase/Firebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {toast} from 'react-hot-toast'

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const notify = () => toast.success('Login Successfully.');


  function firstClick() {
  
      logInWithEmailAndPassword(email, password).then(() =>
        navigate("/Home")
      )
      notify()
  }
  
  



  return (
    <div className="log-bg">
      <div className="log-form-p">
        <div className="logo-pp5">
          <img src={require("../../Img/logo.png")} alt="" />
        </div>
        <h1 className="log-h1">LOG IN</h1>
        <div className="log-form">
          {/* <form> */}
  
          <div className="log-fgg">

            <input
              required
              className="input-log"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              name="email"
              id=""
            />
            <div className="passworld-hide-show">

            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-log"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              
              
              name=""
              id=""
              />
                <div
                              className="password-icon2"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
              </div>

              
            <button
            onClick={() => {firstClick(); }}

              // onClick={()=> navigate('./SignUp')}
              className="log-bb1"
            >
              LOG IN
            </button>

          </div>
            
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
