import React, { useState } from "react";
import "./Profile.css";
import Image from "../../Img/user-temp.png";
import { AiFillCamera, AiOutlineClose } from "react-icons/ai";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaUserCircle } from "react-icons/fa";
import { BsFillReplyFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {toast} from 'react-hot-toast'

import Modal from "react-modal";
const Profile = () => {
  const notify = () => toast.success('Save Successfully.');
  function firstClick() {
    // add another onclick funcion 
  
      // logInWithEmailAndPassword(email, password).then(() =>
      //   navigate("/Home")
      // )
      notify()
  }
  

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }



  const [profilePicture, setProfilePicture] = useState(Image);

  function handleChange(event) {
    setProfilePicture(URL.createObjectURL(event.target.files[0]));
  }
const navigate = useNavigate();
  const [toggleState, setToggleState] = useState(1);
  const [toggleState1, setToggleState1] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const toggleTab1 = (index) => {
    setToggleState1(index);
  };

  return (
    <div className="Profile-bg">
      <div className=" body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="profile-div-1-1 main-y">
            <div className="profile-div-1-2">
              <div className="user-profile-name-pic">
                <div className="user-profile-name">
                  <div class="profile-div-1 profile-pic">
                    <label class="-label" for="file">
                      <span>
                        <AiFillCamera size={30} />
                      </span>
                    </label>
                    <img src={profilePicture} id="output" width="200" />
                    <input id="file" type="file" onChange={handleChange} />
                  </div>
                  <div className="user-name-profile">
                    <h1>
                      Ranjan
                      <span className="span-1-profile">
                        Joined Mar 2022 • Active now
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
              <Tabs className='profile-tab-heads'>
                <TabList>
                  <div className="tab-list-title">
                    <Tab
                      onClick={() => toggleTab(1)}
                      className={toggleState === 1 ? "bb1" : "bb11"}
                    >
                      <h1 className="title-tab-1">Profile</h1>
                    </Tab>

                    <Tab
                      onClick={() => toggleTab(2)}
                      className={toggleState === 2 ? "bb1" : "bb11"}

                      // className="tab-1-profile-f"
                    >
                      <h1 className="title-tab-1">Forums</h1>
                    </Tab>
                  </div>
                </TabList>

                <TabPanel>
                  <div className="panel-head-1">
                    <div className="tab-panel-1-profile">
                      <h1>Details</h1>
                      <button 
                      onClick={openModal} 
                      className="edit-btn-profile">Edit</button>
                    </div>
                    <hr />
                    <div className="td-profile-1">
                      <tbody className="tbody-profile-1">
                        <tr className="tr-profile-1">
                          <td>First Name</td>
                          <td>Ranjan Sharma</td>
                        </tr>
                        <tr className="tr-profile-1">
                          <td>Last Name</td>
                          <td>Sharma</td>
                        </tr>
                        <tr className="tr-profile-1">
                          <td>Nickname</td>
                          <td>bodybuildingalchemist</td>
                        </tr>
                      </tbody>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    <TabList>
                      <div className="tab-list-title1">
                        <Tab
                          onClick={() => toggleTab1(1)}
                          className={toggleState1 === 1 ? "bb1" : "bb11"}
                        >
                          <h1 className="title-tab-1">My Discussions</h1>
                        </Tab>
                        <Tab
                          onClick={() => toggleTab1(2)}
                          className={toggleState1 === 2 ? "bb1" : "bb11"}
                        >
                          <h1 className="title-tab-1">My Replies</h1>
                        </Tab>
                      </div>
                    </TabList>
                    <hr />

                    
                    <div className="tabs-2-1">
                      <TabPanel>
                      <h2 className="h1-tabs-2">Forum Discussions Started</h2>

                        <div className="all-Discussions-tab1">
                          <h2 className="heading-tabs-2">All Discussions</h2>
                          <hr />

                          <div className="tab-data-5">
                            <div>
                              <FaUserCircle size={30} />
                            </div>

                            <div className="tab-data-6">
                              <div>
                                <h1 
                                 onClick={()=> navigate('/Reply')}
                                className="tab-data-6-h1">
                                  Making the weight class
                                </h1>
                                <div className="section-1-p">

                                <div className="forum-reply-profile">
                                  <BsFillReplyFill size={30} />
                                  <h1 className="tab-data-6-h2">
                                    <span 
                                    onClick={()=> navigate('/Reply')}
                                    className="profile-span-2">John </span>
                                    replied
                                    <span
                                     onClick={()=> navigate('/Reply')}
                                    className="profile-span-3"
                                    >1 month, 1 week ago </span>3 Members ·
                                    6 Replies
                                  </h1>
                                </div>
                              <div>
                       <button
                        onClick={()=> navigate('/')}
                       
                       className="btn-prep-pro">Contest Prep</button>
                                    </div>
                              </div>

                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="tab-data-5">
                            <div>
                              <FaUserCircle size={30} />
                            </div>

                            <div className="tab-data-6">
                              <div>
                                <h1 
                                 onClick={()=> navigate('/Reply')}
                                className="tab-data-6-h1">
                                  Making the weight class
                                </h1>
                                <div className="section-1-p">

                                <div className="forum-reply-profile">
                                  <BsFillReplyFill size={30} />
                                  <h1 className="tab-data-6-h2">
                                    <span 
                                    onClick={()=> navigate('/Reply')}
                                    className="profile-span-2">John </span>
                                    replied
                                    <span
                                     onClick={()=> navigate('/Reply')}
                                    className="profile-span-3"
                                    >1 month, 1 week ago </span>3 Members ·
                                    6 Replies
                                  </h1>
                                </div>
                              <div>
                       <button
                        onClick={()=> navigate('/')}
                       
                       className="btn-prep-pro">Contest Prep</button>
                                    </div>
                              </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                      <h2 className="h1-tabs-2">Forum Reply Started</h2>
                        <div className="all-Discussions-tab1">
                          <h2 className="heading-tabs-2">
My Replies</h2>
                          <hr />

                          <div className="tab-data-5">
                            <div>
                              <FaUserCircle size={30} />
                            </div>

                            <div className="tab-data-6">
                              <div>
                                <h1 
                                 onClick={()=> navigate('/Reply')}
                                className="tab-data-6-h1">
                                  Making the weight class
                                </h1>
                                <div className="section-1-p">

                                <div className="forum-reply-profile">
                                  <BsFillReplyFill size={30} />
                                  <h1 className="tab-data-6-h2">
                                    <span 
                                    onClick={()=> navigate('/Reply')}
                                    className="profile-span-2">John </span>
                                    replied
                                    <span
                                     onClick={()=> navigate('/Reply')}
                                    className="profile-span-3"
                                    >1 month, 1 week ago </span>3 Members ·
                                    6 Replies
                                  </h1>
                                </div>
                              <div>
                       <button
                        onClick={()=> navigate('/')}
                       
                       className="btn-prep-pro">Contest Prep</button>
                                    </div>
                              </div>

                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="tab-data-5">
                            <div>
                              <FaUserCircle size={30} />
                            </div>

                            <div className="tab-data-6">
                              <div>
                                <h1 
                                 onClick={()=> navigate('/Reply')}
                                className="tab-data-6-h1">
                                  Making the weight class
                                </h1>
                                <div className="section-1-p">

                                <div className="forum-reply-profile">
                                  <BsFillReplyFill size={30} />
                                  <h1 className="tab-data-6-h2">
                                    <span 
                                    onClick={()=> navigate('/Reply')}
                                    className="profile-span-2">John </span>
                                    replied
                                    <span
                                     onClick={()=> navigate('/Reply')}
                                    className="profile-span-3"
                                    >1 month, 1 week ago </span>3 Members ·
                                    6 Replies
                                  </h1>
                                </div>
                              <div>
                       <button
                        onClick={()=> navigate('/')}
                       
                       className="btn-prep-pro">Contest Prep</button>
                                    </div>
                              </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button> */}

    
          <div className="header-modal-edit2">
            <div className="user-dp-modal-reply">
              <FaUserCircle />
            </div>
            <div className="heading-h1-edit">
            <h1>Edit "Details" Information</h1>
            </div>

            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal} />
            </div>
          </div>
      
          <hr />
          {/* textarea-modal */}
          <form >
<div className="profile-edit-setting">

          <div>
          <label className="label-1acc">First Name</label>
                            <input
                              className="input-box-1-acc"
                              type="email"
                              name=""
                              id=""
                              required
                            />
          </div>
          <div>
          <label className="label-1acc">Last Name</label>
                            <input
                              className="input-box-1-acc"
                              type="email"
                              name=""
                              id=""
                              required
                            />
          </div>
          <div>
          <label className="label-1acc">Nickname</label>
                            <input
                              className="input-box-1-acc"
                              type="email"
                              name=""
                              id=""
                              required
                            />
          </div>
        
</div>
    
     
           <div className="save-change-btn-div"> 
            <button 
               onClick={() => {firstClick(); }}
            
            className="save-change-btn">Save Change</button>
           </div>

        
          
          </form>
      
      </Modal>
      </div>
    </div>
  );
};

export default Profile;
