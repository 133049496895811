import React from "react";
import "./UserPlan.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";

const UserPlan = () => {
  return (
    <div className="plan-bg">
      <div className=" body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>

          <div className="tb-plan1 main-y">
            <div className="tb-plan">
              <div>
                <div>
                  <h1 className="plan">Account</h1>
                </div>

             
                <div>
                  <table className="tb-1">
                    <caption className="tb-cap">Payments</caption>
                    <thead>
                      <tr className="tr-plan">
                        <th className="th-plan" scope="col">
                          Membership
                        </th>
                        <th className="th-plan" scope="col">
                          Subscription
                        </th>
                        <th className="th-plan " scope="col">
                          Active
                        </th>
                        <th className="th-plan" scope="col">
                          Created
                        </th>
                        <th className="th-plan" scope="col">
                          Expires
                        </th>
                        <th className="th-plan" scope="col">
                          Card Exp.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-plan">
                        <td className="td-plan" data-label="  Membership">Main course + Post Contest</td>
                        <td  className="td-plan"  data-label="     Subscription">Lifetime</td>
                        <td className="td-plan yes-1" data-label="Active ">Yes</td>
                        <td className="td-plan" data-label=" Created">1 March 2022</td>
                        <td className="td-plan" data-label="Expires">Never</td>
                        <td  className="td-plan" data-label=" Card Exp."> N/A </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table className="tb-1">
                    <caption className="tb-cap">Payments</caption>
                    <thead>
                      <tr className="tr-plan">
                        <th className="th-plan" scope="col">
                          Membership
                        </th>
                        <th className="th-plan" scope="col">
                          Subscription
                        </th>
                        <th className="th-plan" scope="col">
                          Active
                        </th>
                        <th className="th-plan" scope="col">
                          Created
                        </th>
                        <th className="th-plan" scope="col">
                          Expires{" "}
                        </th>
                        <th className="th-plan" scope="col">
                          Card Exp.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-plan">
                        <td className="td-plan" data-label="  Membership">Main course + Post Contest</td>
                        <td  className="td-plan"  data-label="     Subscription">Lifetime</td>
                        <td className="td-plan yes-1" data-label="Active">Yes</td>
                        <td className="td-plan" data-label=" Created">1 March 2022</td>
                        <td className="td-plan" data-label="Expires">Never</td>
                        <td  className="td-plan" data-label=" Card Exp."> N/A </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table className="tb-1">
                    <caption className="tb-cap">Payments</caption>
                    <thead>
                      <tr className="tr-plan">
                        <th className="th-plan" scope="col">
                          Membership
                        </th>
                        <th className="th-plan" scope="col">
                          Subscription
                        </th>
                        <th className="th-plan" scope="col">
                          Active
                        </th>
                        <th className="th-plan" scope="col">
                          Created
                        </th>
                        <th className="th-plan" scope="col">
                          Expires{" "}
                        </th>
                        <th className="th-plan" scope="col">
                          Card Exp.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-plan">
                        <td className="td-plan" data-label="  Membership">Main course + Post Contest</td>
                        <td  className="td-plan"  data-label="     Subscription">Lifetime</td>
                        <td className="td-plan yes-1" data-label="Active">Yes</td>
                        <td className="td-plan" data-label=" Created">1 March 2022</td>
                        <td className="td-plan" data-label="Expires">Never</td>
                        <td  className="td-plan" data-label=" Card Exp."> N/A </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPlan;
