import React from "react";
import "./Course.css";
import { GrDocumentDownload } from "react-icons/gr";
import Sidebar2 from "../../Features/Sidebar/Sidebar2";

const Course = () => {
  return (
    <div className="course-bg">
      <div className=" body-fix-h">
        <div className="wrapper ">
        <div className="sidebar-home-d">
            <Sidebar2/>
          </div>

      <div className="course-info main-y" >

        <h1 className="course-h1">Course Details</h1>
      <div className="pdf-logo">
        <div className="flx-cor">
          <form method="get" action="file.doc">
            <a href={require("../../Img/CPA_.pdf")} download="ContestPrep">
              <div className="flx-cor">
                <GrDocumentDownload className="svg-pdf" />
                {/* <h1 className="course-h2">Download Pdf</h1> */}
              </div>
            </a>
          </form>
        </div>
      </div>

      <div className="pdf-about-pages">
        <img src={require("../../Img/1.jpg")} alt="" />
        <img src={require("../../Img/2.jpg")} alt="" />
        <img src={require("../../Img/3.jpg")} alt="" />
        <img src={require("../../Img/4.jpg")} alt="" />
        <img src={require("../../Img/5.jpg")} alt="" />
        <img src={require("../../Img/6.jpg")} alt="" />
        <img src={require("../../Img/7.jpg")} alt="" />
        <img src={require("../../Img/8.jpg")} alt="" />
        <img src={require("../../Img/9.jpg")} alt="" />
        <img src={require("../../Img/10.jpg")} alt="" />
        <img src={require("../../Img/11.jpg")} alt="" />
        <img src={require("../../Img/12.jpg")} alt="" />
        <img src={require("../../Img/13.jpg")} alt="" />
        <img src={require("../../Img/14.jpg")} alt="" />
        <img src={require("../../Img/15.jpg")} alt="" />
        <img src={require("../../Img/16.jpg")} alt="" />
        <img src={require("../../Img/17.jpg")} alt="" />
        <img src={require("../../Img/18.jpg")} alt="" />
        <img src={require("../../Img/19.jpg")} alt="" />
        <img src={require("../../Img/20.jpg")} alt="" />
        <img src={require("../../Img/21.jpg")} alt="" />
        <img src={require("../../Img/22.jpg")} alt="" />
        <img src={require("../../Img/23.jpg")} alt="" />
        <img src={require("../../Img/24.jpg")} alt="" />
        <img src={require("../../Img/25.jpg")} alt="" />
        <img src={require("../../Img/26.jpg")} alt="" />
        <img src={require("../../Img/27.jpg")} alt="" />
        <img src={require("../../Img/28.jpg")} alt="" />
        <img src={require("../../Img/29.jpg")} alt="" />
        <img src={require("../../Img/30.jpg")} alt="" />
        <img src={require("../../Img/31.jpg")} alt="" />
        <img src={require("../../Img/32.jpg")} alt="" />
        <img src={require("../../Img/33.jpg")} alt="" />
        <img src={require("../../Img/34.jpg")} alt="" />
        <img src={require("../../Img/35.jpg")} alt="" />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default Course;
